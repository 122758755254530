<template>
  <!-- 禁止点击遮罩关闭，因为放大裁剪框的时候会导致触发关闭 -->
  <el-dialog
    v-if="crop.show"
    :visible="crop.show"
    :show-close="false"
    :close-on-click-modal="false && !status"
    :close-on-press-escape="!status"
    title="图片裁剪"
    class="crop-dialog dialog-vertical"
    @close="close"
    append-to-body
    :modal="modal"
    :width="multiple ? '900px' : '600px'"
  >
    <!-- 选择本地图片 -->
    <!--    <input type="file" accept="image/*" @change="inputChange"/>-->

    <!-- 加了反而出不来 -->
    <!--    <img crossorigin="anonymous" :src="crop.src" />-->
    <!-- 能正常显示 -->
    <!--    <img :src="crop.src" />-->

    <!-- 都能正常显示，响应头携带了 -->
    <!-- access-control-allow-methods: GET, POST, PUT, DELETE, HEAD -->
    <!-- access-control-allow-origin: * -->
    <!--    <img style="width: 200px" crossorigin="anonymous" src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-a90b5f95-90ba-4d30-a6a7-cd4d057327db/423744c0-5673-4a14-adca-14bd986c3f05.jpg" />-->
    <!--    <img style="width: 200px" src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-a90b5f95-90ba-4d30-a6a7-cd4d057327db/423744c0-5673-4a14-adca-14bd986c3f05.jpg" />-->

    <div class="content flex" v-loading="loading">
      <div
        :style="{
          marginRight:
            crop.src && multiple && cutList.length > 1
              ? 'calc(85px + 12px)'
              : '',
        }"
        class="crop-container"
        :class="graphicsState === 'square' ? '' : 'circular-w'"
        v-if="imgLocalData"
      >
        <vueCropper
          ref="cropper"
          :img="imgLocalData"
          :outputSize="option.size"
          :outputType="option.outputType"
          :autoCrop="option.autoCrop"
          :fixed="fixed"
          :fixedNumber="
            activeFixedNum !== -1
              ? fixedNumberBtn[activeFixedNum].scale
              : [myWidth, myHeight]
          "
          :centerBox="option.centerBox"
          :enlarge="option.enlarge"
          :original="option.original"
        />
        <!--        <vue-picture-cut ref="pictureCut" :src="src"-->
        <!--                         :format="format"-->
        <!--                         :mskOption="maskOptions"-->
        <!--                         @change="cutChange"-->
        <!--                         @on-change="cutChange">-->
        <!--                    <vue-picture-cut-menu-->
        <!--                        slot="menu"-->
        <!--                        :cancel="false"-->
        <!--                        confirm-name="确定"-->
        <!--                        cancel-name="取消"-->
        <!--                        size-auto-name="自动"-->
        <!--                        size-raw-name="原始"-->
        <!--                        menu-rotate-name="旋转"-->
        <!--                        @on-change="cutChange"-->
        <!--                    />-->
        <!--        </vue-picture-cut>-->
      </div>
      <el-scrollbar
        v-if="crop.src && multiple && cutList.length > 1"
        class="cut-list w-85px h-335px flex-col overflow-y-auto ml-12px"
      >
        <div class="min-h-335px flex-col flex-center">
          <div
            v-for="(cut, cutI) in cutList"
            :key="cut.src"
            class="thumbnail-box"
          >
            <el-image
              :id="`thumbnail${cutI}`"
              class="s-85 outline mt-8px outline-offset--3"
              fit="cover"
              :style="{
                outlineColor:
                  current === cut.src
                    ? 'var(--el-color-primary)'
                    : 'transparent',
                cursor: current === cut.src ? 'default' : 'pointer',
              }"
              :src="cut.src"
              @click.native="switchSrc(cut.src)"
            />
            <div
              v-if="cut.res && cut.init"
              class="success flex"
              style="background-color: white"
            >
              <i
                class="el-icon-success"
                style="font-size: 26px; color: var(--el-color-success)"
              ></i>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>

    <!-- 结果 -->
    <div v-if="false && multiple" class="result-list flex">
      <div
        class="outline outline-offset--3 flex-col flex-align-center ml-12px mt-8px"
        :style="{
          outlineColor:
            current === cut.src ? 'var(--el-color-primary)' : 'transparent',
          cursor: current === cut.src ? 'default' : 'pointer',
        }"
        v-for="(cut, cutI) in cutList"
        :key="cut.src"
      >
        <el-image
          fit="contain"
          :src="cut.res ? cut.res.url : cut.src"
          class="s-85"
        ></el-image>

        <div class="flex-center">
          <el-button
            @click="
              $previewImage(
                cutList.map((c) => (c.res ? c.res.url : c.src)),
                cutI
              )
            "
            type="text"
            >预览</el-button
          >
          <!--后悔了，不想选这张图了-->
          <el-button
            :disabled="cutList.length < 3"
            @click="cutList.splice(cutI, 1)"
            type="text"
            >删除</el-button
          >
          <!--后悔了，不想选这张图了-->
        </div>
      </div>
    </div>

    <!--    <el-form size="small">-->
    <!--      &lt;!&ndash; TODO: 实时预览效果 &ndash;&gt;-->
    <!--      <el-form-item>-->
    <!--&lt;!&ndash;        <div style="width: 200px; height: 200px;" :style="{backgroundImage: `url(${base64})`}" />&ndash;&gt;-->
    <!--        &lt;!&ndash;choose picture&ndash;&gt;-->
    <!--&lt;!&ndash;        <a class="download-img"&ndash;&gt;-->
    <!--&lt;!&ndash;           download="vue-picture-cut.jpeg"&ndash;&gt;-->
    <!--&lt;!&ndash;           :href="base64"&ndash;&gt;-->
    <!--&lt;!&ndash;           :style="{'background-image': `url(${newSrc})`}">&ndash;&gt;-->
    <!--&lt;!&ndash;          download&ndash;&gt;-->
    <!--&lt;!&ndash;        </a>&ndash;&gt;-->
    <!--      </el-form-item>-->
    <!--      <el-form-item label="导出格式：">-->
    <!--        <el-select v-model="format">-->
    <!--          <el-option label="jpeg" value="image/jpeg" />-->
    <!--          <el-option label="png" value="image/png" />-->
    <!--        </el-select>-->
    <!--      </el-form-item>-->
    <!--      <el-form-item label="裁剪形状：">-->
    <!--        <el-select v-model="maskOptions.isRound">-->
    <!--          <el-option label="矩形" :value="false" />-->
    <!--          <el-option label="椭圆形" :value="true" />-->
    <!--        </el-select>-->
    <!--      </el-form-item>-->
    <!--    </el-form>-->
    <div slot="footer" class="footer">
      <!-- 预设尺寸 -->
      <div class="crop-btn-group">
        <el-button
          v-for="(btn, i) in fixedNumberBtn"
          :key="btn.title"
          size="small"
          :class="{ active: i === activeFixedNum }"
          @click="switchFixed(i)"
          >{{ btn.title }}
        </el-button>
        <div class="divider"></div>
        <div class="custom-mode">
          <el-popover placement="top" title="" trigger="hover">
            <template #reference>
              <el-button
                :class="{ active: activeFixedNum === -1 }"
                size="small"
                @click="customFixed"
                >自定义（{{ myWidth }}:{{ myHeight }}）</el-button
              >
            </template>
            <el-input-number
              v-model="myWidth"
              :min="1"
              controls-position="right"
              size="small"
              @focus="customFixed"
              @change="customFixed"
            ></el-input-number>
            <span style="margin: 0 10px">:</span>
            <el-input-number
              v-model="myHeight"
              :min="1"
              controls-position="right"
              size="small"
              @focus="customFixed"
              @change="customFixed"
            ></el-input-number>
          </el-popover>
        </div>

        <div class="flex-1"></div>

        <el-radio-group v-model="graphicsState" size="small">
          <el-radio-button label="square" type="plain">方形</el-radio-button>
          <el-radio-button label="circular" type="plain">圆形</el-radio-button>
        </el-radio-group>
      </div>
      <!-- 建议尺寸 -->
      <div v-if="crop.ratioList.length > 1" class="advise-mode">
        <el-radio-group
          v-model="advisedRatio"
          class="flex-col"
          style="text-align: left"
          @change="onAdvised"
        >
          <el-radio
            style="margin-top: 20px"
            v-for="c in crop.ratioList"
            :key="c.ratio"
            :label="c.ratio"
            >{{ c.desc || c.ratio }}</el-radio
          >
        </el-radio-group>
      </div>
      <div class="dialog-btn-group">
        <el-button size="small" :disabled="status" @click="cancel"
          >取 消</el-button
        >
        <!--        <el-button size="small" :disabled="status" @click="doCut">预览</el-button>-->
        <el-button type="primary" size="small" :loading="status" @click="save">
          <span v-if="!status">{{
            multiple && cutList.length > 1 ? '全部保存' : '保 存'
          }}</span>
          <span v-else>保存中</span>
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getFileType, getDataUrl, randomString } from '@/base/utils/tool'
import { mapState } from 'vuex'

/**
 * Notice: 支持裁剪椭圆的形状
 * https://light-year.gitee.io/vue-picture-cut/
 */
import { VueCropper } from 'vue-cropper'

// import {VuePictureCut, VuePictureCutMenu, createUtils} from 'vue-picture-cut';

import filesUpload from '@/base/utils/upload4'

export default {
  name: 'CropDialog',
  components: {
    VueCropper,
    // VuePictureCut,
    // VuePictureCutMenu
  },
  inject: ['mediaKey'],
  props: {
    // 是否需要遮罩层
    modal: {
      type: Boolean,
      default: true,
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
    configApi: String,
  },
  data() {
    return {
      loading: false, //图片加载
      status: false, //保存状态
      imgLocalData: null, //图片本地数据
      option: {
        outputSize: 1, //裁剪生成图片的质量
        outputType: 'png', //裁剪生成图片的格式
        autoCrop: true, //是否默认生成截图框
        centerBox: true, //截图框是否被限制在图片里面
        enlarge: 4, // 图片根据截图框输出比例倍数，0 ~ max(建议不要太大不然会卡死的呢)
        original: false,
      },
      fixed: false, //是否开启截图框宽高固定比例
      activeFixedNum: 0, //截图框选中的宽高比例下标
      fixedNumberBtn: [
        { title: '自由', scale: [] },
        { title: '5:4', scale: [5, 4] },
        { title: '3:2', scale: [3, 2] },
        { title: '16:9', scale: [16, 9] },
        { title: '4:3', scale: [4, 3] },
        { title: '1:1', scale: [1, 1] },
      ],
      /* 手动输入比例 */
      myWidth: 2,
      myHeight: 1,
      src: null,
      blob: null,
      base64: null,
      maskOptions: {
        isRound: false,
        resize: true,
      },
      format: 'image/jpeg',
      previewData: null,
      utils: null,

      cutList: [],

      advisedRatio: '', // 从建议的比例中选一个出来的

      current: '', // 多图批量裁剪，这个是当前正在裁剪的原始url（本地文件的话是 blob://）

      graphicsState: 'square',
    }
  },
  computed: {
    ...mapState(['crop']),
    // newSrc () {
    //   return this.blob ? URL.createObjectURL(this.blob) : '';
    // }
    multiple() {
      return this.crop.src && typeof this.crop.src !== 'string' // 数组就是多图（长度为1的话暂时没优化）
    },
  },
  watch: {
    'crop.show'(val) {
      if (val) {
        this.initCrop()
      } else {
        // this.utils?.reset()
      }
    },
  },
  mounted() {
    // console.log('mounted: cropDialog')
    // console.log(this.$refs, 'refs')
    // if (this.$refs['pictureCut']) this.utils = createUtils(this.$refs['pictureCut']);
  },
  methods: {
    /**
     * 选中了建议尺寸
     */
    onAdvised(e) {
      if (e) {
        // this.activeFixedNum = -1
        let wAndH = e.split(':').map((el) => el.trim())
        if (wAndH.length === 1) {
          wAndH = e.split('*').map((el) => el.trim())
        }
        this.myWidth = wAndH[0]
        this.myHeight = wAndH[1]
        this.customFixed()
      }
    },
    switchSrc(src = '') {
      console.log('switch src...')
      this.saveSingle(() => {
        console.log('save callback...')
        this.current = src
        this.imgLocalData = ''
        // const index = this.cutList.findIndex((el) => el.src === src)
        // const isInit = this.cutList[index].init === true
        // if (!isInit) {
        //   // itemRefs[index]?.handleOpen({
        //   //   name: getFileName(this.cutList[index]),
        //   //   src
        //   // })
        //   this.switchInit(src, index)
        //   this.cutList[index].init = true
        // }

        this.switchInit(src)

        document
          .getElementById(`thumbnail${index}`)
          .scrollIntoView({ behavior: 'smooth', block: 'center' })
      })
    },
    // doCut(){
    //   console.log('do cut')
    //   console.log(this.utils)
    //   if (!this.utils) this.utils = createUtils(this.$refs['pictureCut']);
    //   const res = this.utils.cut();
    //   if (res) {
    //     this.blob = res.file;   // BLOB对象
    //     this.base64 = res.src;  // base64字符串
    //   }
    // },
    // inputChange(e) {
    //   const file = e.target.files[0];
    //   this.src = URL.createObjectURL(file);
    // },
    /**
     * @param blob      BLOB object
     * @param base64    Base64 string
     */
    // cutChange({ blob, base64 }) {
    //   console.log('cut change', blob, base64)
    //   this.blob = blob;
    //   this.base64 = base64;
    // },
    close() {
      this.imgLocalData = null
      this.fixed = false
      this.activeFixedNum = 0
      this.cancel()
    },
    switchInit(src = '') {
      console.log('switch init...')
      this.loading = true

      let imgSrc

      const isWebImage =
        src.indexOf('https://') !== -1 && src.indexOf('blob:https://') === -1

      if (isWebImage) {
        imgSrc = src + '?' + randomString()
      } else {
        imgSrc = src // 网络图片
      }

      // 获取图片格式
      this.option.outputType = getFileType(imgSrc)
      if (this.option.outputType === 'jpg') {
        this.option.outputType = 'jpeg'
      }
      if (this.option.outputType.split('?')[0] === 'png') {
        // 去除连接符
        this.option.outputType = 'png'
      }
      // 在线转本地
      if (isWebImage)
        getDataUrl(imgSrc, this.option.outputType)
          .then((res) => {
            // console.log(res)
            this.imgLocalData = res // base 64 里面的数据是 jpeg
            // this.imgLocalData = imgSrc
            //     this.src = res
            // this.loading = false
            setTimeout(() => {
              this.loading = false
              console.log('loading end. ')
            }, 3500)
          })
          .catch((err) => {
            console.log(err)
            this.loading = false
          })
      else {
        this.imgLocalData = imgSrc
        setTimeout(() => {
          this.loading = false
          console.log('loading end. ')
        }, 3500)
      }

      // console.log(this.imgLocalData)
    },
    // 初始化裁剪
    initCrop() {
      console.log('init crop')
      this.loading = true
      // let imgSrc = this.crop.src;

      if (this.crop.ratioList?.length > 1) this.advisedRatio = ''
      else if (this.crop.ratioList?.length === 1)
        this.onAdvised(this.crop.ratioList[0].ratio)
      else this.advisedRatio = ''

      console.log(this.crop.ratioList)

      let cropSrc

      if (this.multiple) {
        this.cutList = this.crop.src.map((c) => ({
          src: typeof c === 'string' ? c : c.url,
          init: false,
          res: typeof c === 'string' ? null : c || null,
        }))
        cropSrc = this.cutList[0].src
      } else {
        // this.cutList = [{
        //   src: this.crop.src,
        //   init: false,
        //   res: null
        // }]
        cropSrc = this.crop.src
      }

      console.log(this.cutList)

      this.graphicsState = 'square' // 改变裁剪形状，仅支持方形、圆形

      this.current = cropSrc

      let imgSrc

      const isWebImage =
        cropSrc.indexOf('https://') !== -1 &&
        cropSrc.indexOf('blob:https://') === -1
      console.log(isWebImage, '是否网络图片？')

      if (isWebImage) {
        imgSrc = cropSrc + '?' + randomString()
      } else {
        imgSrc = cropSrc // base64，这个就不要加 ?xxx 了
      }

      // let imgSrc = 'https://vkceyugu.cdn.bspapp.com/VKCEYUGU-a90b5f95-90ba-4d30-a6a7-cd4d057327db/423744c0-5673-4a14-adca-14bd986c3f05.jpg'
      // 获取图片格式
      this.option.outputType = getFileType(imgSrc)
      if (this.option.outputType === 'jpg') {
        this.option.outputType = 'jpeg'
      }
      if (this.option.outputType.split('?')[0] === 'png') {
        // 去除连接符
        this.option.outputType = 'png'
      }
      // 在线转本地
      if (isWebImage)
        getDataUrl(imgSrc, this.option.outputType)
          .then((res) => {
            // console.log(res) // base64
            this.imgLocalData = res // base 64 里面的数据是 jpeg
            // this.imgLocalData = imgSrc
            //     this.src = res
            this.loading = false
          })
          .catch((err) => {
            console.log(err)
            this.loading = false
          })
      else this.imgLocalData = imgSrc
      this.loading = false

      // console.log(this.imgLocalData)

      // this.src = imgSrc
      // this.loading = false
    },
    // 切换比例
    switchFixed(index) {
      this.fixed = !!index
      this.activeFixedNum = index
      this.advisedRatio = null
      setTimeout(() => {
        this.$refs.cropper.goAutoCrop()
      }, 300)
    },
    customFixed() {
      this.fixed = true
      this.activeFixedNum = -1

      // if (!this.multiple) {
      setTimeout(() => {
        this.$refs.cropper?.goAutoCrop()
      }, 300)
      // }
    },
    /**
     * 保存当前的裁剪
     * 切换前或者点击全部保存，要触发裁剪上传
     */
    saveSingle(callback) {
      // this.status = true
      this.loading = true
      // 获取截图的blob数据
      this.$refs.cropper.getCropBlob((data) => {
        let fileName = '截图' + new Date().getTime() + '.png'

        if (this.graphicsState === 'circular') {
          // 此时的blob数据实际上是一个autoCropWidth*autoCropHeight的方形图片数据
          // 因此需要在此图的基础上进一步裁剪
          this.drawAndClipImage(data, fileName, (file) => {
            filesUpload({
              configApi: this.configApi || '/admin/admin/media/requestUpload',
              data: file,
              pid: this.crop.pid,
            })
              .then((res) => {
                console.log(res.data)
                if (this.multiple) {
                  const cutIndex = this.cutList.findIndex(
                    (c) => c.src === this.current
                  )
                  this.cutList[cutIndex].res = {
                    ...res.data,
                    type: res.data.type === 2 ? 'image' : res.data.type,
                  }
                  this.cutList[cutIndex].init = true
                }

                console.log('before callback')
                callback(res)
                console.log('callback then')

                // this.status = false
                // this.loading = false
              })
              .catch(() => {
                this.status = false
                this.loading = false
              })
          })
          return
        }

        filesUpload({
          configApi: this.configApi || '/admin/admin/media/requestUpload',
          data: new window.File([data], fileName, { type: 'image/png' }),
          pid: this.crop.pid,
        })
          .then((res) => {
            console.log(res.data)
            if (this.multiple) {
              const cutIndex = this.cutList.findIndex(
                (c) => c.src === this.current
              )
              this.cutList[cutIndex].res = {
                ...res.data,
                type: res.data.type === 2 ? 'image' : res.data.type,
              }
              this.cutList[cutIndex].init = true
            }

            console.log('before callback')
            callback(res)
            console.log('callback then')

            // this.status = false
            // this.loading = false
          })
          .catch(() => {
            this.status = false
            this.loading = false
          })
      })
    },
    // 保存
    save() {
      if (
        this.cutList.length &&
        this.cutList.filter((c) => !c.res && c.src !== this.current).length
      ) {
        this.$alert('请裁剪好所有图片再上传')
        return
      }

      this.status = true // 保存按钮锁定

      if (this.isDialog) this.$store.commit('media/setIsRefresh', true)

      // 把当前的保存上传到媒体库就可以关掉了
      this.saveSingle((res) => {
        // this.$emit('refresh') // 应该是用来刷新媒体库弹窗数据的？

        console.log(this.isDialog, this.multiple, 'save?? ')
        if (this.isDialog) {
          if (this.multiple) {
            // 点确定就所有图都保存了咯
            this.$bus.emit('confirm-media-dialog', {
              // 一个页面内可能有多个裁剪弹窗，crop.show 唤起的不一定是同一个 mediaKey 的
              mediaKey: this.$store.state.crop.mediaKey || this.mediaKey,
              data: this.cutList.map((c) => {
                if (c.res) {
                  // 裁剪过，或者从列表进入裁剪网络图片而非本地选图裁剪
                } else {
                  // 未裁剪??，不能提交
                }
                return {
                  ...c.res,
                  old_url: c.src,
                }
              }),
            })
          } else {
            // TODO: 自动选中并保存
            this.$bus.emit('confirm-media-dialog', {
              mediaKey: this.$store.state.crop.mediaKey || this.mediaKey,
              data: {
                ...res.data,
                type: res.data.type === 2 ? 'image' : '',
                old_url: this.current,
              },
            })
          }
        } else {
          // 刷新媒体库列表
          // this.$store.commit('media/setIsRefresh', true)
        }

        this.close()
        this.status = false

        // if (!this.isDialog) this.$store.commit('media/setIsRefresh', true) // 自己刷新的，万一原图不在第一页
      })
    },
    cancel() {
      this.$store.commit('crop/setShow', false)
    },
    /**
     * 新增方法(截圆形图时使用)
     * 将截图的 blob 数据绘制成图片，然后进一步根据需要裁剪成圆形图片
     */
    drawAndClipImage(file, fileName, callback) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        const src = e.target.result
        const image = new Image()
        image.src = src
        image.onload = () => {
          const canvas = document.createElement('canvas')
          const width = image.width
          const height = image.height
          canvas.width = width
          canvas.height = height
          // 计算圆形图片的圆心及图片半径
          const circle = {
            x: width / 2,
            y: height / 2,
            r: width / 2,
          }
          const context = canvas.getContext('2d')
          context.clearRect(0, 0, width, height)
          // 在canvas开始绘制前填充白色透明背景并设置透明度，用以清除图片裁剪后透明区域变成黑色的问题
          context.fillStyle = 'rgba(255, 255, 255, 0)'
          context.fillRect(0, 0, width, height)

          // 开始路径画圆，剪切处理
          context.save() // 保存当前canvas的状态
          context.beginPath()
          context.arc(circle.x, circle.y, circle.r, 0, Math.PI * 2, false) // 创建弧/曲线(用于创建圆形或部分圆)
          context.clip() // 从原始画布剪切任意形状和尺寸的区域
          context.drawImage(image, 0, 0)
          context.restore() // 返回之前保存过的路径状态和属性，恢复状态

          // 将canvas图片转换成 blob数据
          canvas.toBlob((blob) => {
            // 内部注释参考 getCropBlob方法 else部分
            const file = new File([blob], fileName, { type: blob.type })
            // this.$emit('emitPar', { imgUrl: URL.createObjectURL(blob), fileData: file })
            callback(file)
          })
        }
        image.onerror = (err) => {}
      }
      reader.onerror = (err) => {}
    },
  },
}
</script>
<style lang="scss" scoped>
/*@import "~vue-picture-cut/lib/vue-picture-cut.css";*/

.crop-dialog {
  --el-color-primary: #{$primary};
  --el-color-success: #{$success};

  ::v-deep .vue-picture-cut_menu-box {
    /*padding-top: 20px;*/
  }

  ::v-deep .vue-picture-cut-menu_confirm {
    /*display: none;*/
  }

  ::v-deep .el-dialog__body {
    padding-top: 10px;
  }

  .content {
    position: relative;

    &::before {
      content: '';
      display: block;
      /*padding-bottom: calc(60% + 120px);*/
      padding-bottom: 60%;
    }

    &::after {
      content: '';
      display: block;
      width: 85px;
      height: 100%;
    }

    .crop-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .footer {
    margin-top: 15px;

    .crop-btn-group {
      display: flex;
      flex-wrap: wrap;

      button,
      .divider {
        margin-bottom: 10px;
      }

      .divider {
        width: 10px;
      }

      button {
        &.active {
          color: #3576ff;
          border-color: #c2d6ff;
          background-color: #ebf1ff;
        }
      }
    }

    .dialog-btn-group {
      text-align: right;
    }
  }
}

$thumbnail-size: 85px;

.w-85px {
  width: $thumbnail-size;
}

.h-335px {
  height: 335px;
}

.ml-12px {
  margin-left: 12px;
}

.s-85 {
  width: $thumbnail-size;
  height: $thumbnail-size;
}

.mt-8px {
  margin-top: 8px;
}

.cut-list {
  position: absolute;
  right: 0;
  height: 100%;

  .thumbnail-box {
    position: relative;
  }

  .success {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;

    .el-icon-success {
      transform: scale(1.2);
    }
  }
}

.outline {
  outline: solid;
}

.outline-offset--3 {
  outline-offset: -3px;
}

/*新增样式如下*/
/*graphicsState等于circular(圆形)时起效，主要用于修改vue-cropper组件的原生样式*/
.circular-w {
  ::v-deep .cropper-view-box {
    border-radius: 50%; // 将裁剪框由方形调整为圆形
  }
  ::v-deep .cropper-face {
    background-color: transparent; // 清除裁剪框填充背景色
  }
}
</style>
