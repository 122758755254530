<template>
  <div class="list-media-item"
       :class="isDialog ? '':'big'"
       @mouseenter="listenEnter"
       @mouseleave="listenLeave"
  >
    <component :is="multiple ? 'el-checkbox' : 'el-radio'"
               :label="label"
               :disabled="disabled"
               @change="$emit('change')"
    >
      <!-- 图片 -->
      <template v-if="item.type === 'image'">
        <img class="list-media-img" v-if="!isDialog"
             :src="fixImageUrl(item.url)"
             :alt="item.title"
             @click.prevent="handlePreview"
        />
        <img class="list-media-img" v-else
             :src="fixImageUrl(item.url)"
             :alt="item.title"
        />
      </template>
      <!-- 视频 -->
      <template v-if="item.type === 'video'">
        <template v-if="!isDialog">
          <img :src="item.config ? fixImageUrl(item.config.thumbnail) : ''"
               :alt="item.title"
               fit="contain"
               class="list-media-img"
               @click.prevent="handlePreview"/>
          <img class="icon-play" :src="videoIcon" @click.prevent="handlePreview"/>
        </template>
        <template v-else>
          <img :src="item.config ? fixImageUrl(item.config.thumbnail) : ''"
               :alt="item.title"
               class="list-media-img"
               fit="contain"/>
          <img class="icon-play" :src="videoIcon"/>
        </template>
      </template>
      <!-- 音频 -->
      <template v-if="item.type === 'audio'">
        <el-image v-if="!isDialog"
                  :src="musicIcon"
                  fit="contain"
                  :alt="item.title"
                  @click.prevent="handlePreview"
        ></el-image>
        <el-image v-else
                  :src="musicIcon"
                  fit="contain"
                  :alt="item.title"
                  style="background: #FECA00;"
        ></el-image>
      </template>
      <!-- 文件 -->
      <el-image v-if="item.type === 'file'"
                :src="fileIcon"
                fit="contain"
                :alt="item.title"
      ></el-image>
      <!-- 文件夹 -->
      <template v-if="item.type === 'folder'">
        <el-image v-if="!isDialog"
                  :src="folderIcon"
                  :alt="item.title"
                  fit="contain"
                  @dblclick.prevent="handlePreview"
                  @click.prevent
        ></el-image>
        <el-image v-else
                  :src="folderIcon"
                  :alt="item.title"
                  fit="contain"
        ></el-image>
      </template>
      <!-- 文件名 -->
      <span class="media-dialog-item-title">{{item.title}}</span>
    </component>
    <!-- 音频预览 -->
    <list-audio v-if="item.type === 'audio' && isDialog"
                :src="item.url"
                :id="item.id"
    ></list-audio>
    <div class="list-media-item-tools"
         :class="[showTools ? 'visible' : '', 'flex-center']"
    >
      <div v-if="!isDialog"
           class="list-media-item-tool flex-center cursor-pointer"
           @click="$emit('open-detail')">
        <i class="el-icon-edit-outline"></i>
      </div>
<!--      <div v-if="item.type === 'image'"-->
<!--           class="list-media-item-tool flex-center cursor-pointer"-->
<!--           @click="$emit('open-crop')">-->
<!--        <i class="el-icon-scissors"></i>-->
<!--      </div>-->
      <div v-if="!isDialog"
           class="list-media-item-tool flex-center cursor-pointer"
           @click="$emit('delete')">
        <i class="el-icon-delete"></i>
      </div>
    </div>
  </div>
</template>

<script>
  import ListAudio from "./ListAudio";

  export default {
    name: "ListMediaItem",
    components: {ListAudio},
    props: {
      // radio-group 选中值，类型不限
      label: {},
      // 是否禁用
      disabled: {
        type: Boolean,
        default: false
      },
      // 选项数据
      item: {
        type: Object,
      },
      isDialog: {
        type: Boolean,
        default: false
      },
      // 是否显示多选框
      multiple: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        videoIcon: require("../../../base/assets/images/media/icon_video_play.png"), //video图标
        fileIcon: require("../../../base/assets/images/media/icon_file.png"), // 文件图标
        musicIcon: require("../../../base/assets/images/media/icon_music.png"), // 音频图标
        folderIcon: require("../../../base/assets/images/media/icon_folder.png"), //文件夹图标
        showTools: false
      }
    },
    methods: {
      listenEnter() {
        this.showTools = true;
      },
      listenLeave() {
        this.showTools = false;
      },
      handlePreview() {
        if (!this.isDialog) {
          switch (this.item.type) {
            case 'image':
              this.$previewImage([this.item.url])
              break;
            case 'video':
              this.$emit('preview-video')
              break;
            case 'audio':
              this.$emit('preview-audio')
              break;
            case 'folder':
              this.$emit('enter-folder')
              break;
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .list-media-item {
    height: 166px;
    border: 1px solid #DCDFE6;
    color: #303133;
    font-weight: normal;
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 11px;
    overflow: hidden;

    &.big {
      height: 208px;

      .media-dialog-item-title {
        height: 57px;
        line-height: 50px;
        padding-top: 7px;
      }

      .el-image, .list-media-img {
        height: 141px;
        top: 10px;
      }
    }

    .el-radio,
    .el-checkbox {
      height: 100%;
      width: 100%;
      padding-top: 12px;
      padding-right: 12px;
      display: flex;
      flex-direction: row-reverse;
    }

    .media-dialog-item-title {
      height: 45px;
      min-height: 45px;
      width: 100%;
      line-height: 45px;
      position: absolute;
      bottom: 0;
      right: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 10px;
    }

    .el-image,
    .list-media-img {
      height: 113px;
      width: 100%;
      position: absolute;
      right: 0;
      top: 8px;
      object-fit: contain;
    }

    .list-audio {
      position: absolute;
      bottom: 45px;
      right: 0;
      z-index: 10;
    }

    .icon-play {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
    }

    .list-media-item-tools {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      transition: all .5s;
      bottom: 33px;
      opacity: 0;
      cursor: pointer;

      &.visible {
        bottom: 51px;
        opacity: 1;
      }

      .list-media-item-tool {
        width: 32px;
        height: 32px;
        font-size: 24px;
        background: rgba(0, 0, 0, .5);
        border-radius: 50%;
        color: white;

        &+.list-media-item-tool {
          margin-left: 26px;
        }
      }
    }
  }
</style>
