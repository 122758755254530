<template>
  <el-popover v-model="show"
              placement="bottom"
              trigger="click"
              :disabled="disabled"
              @show="$emit('show')"
  >
    <slot slot="reference" name="reference"></slot>
    <el-form size="small"
             :style="{
               width: 'calc(' + myWidth + ')'
             }"
             label-position="top"
             @submit.native.prevent
    >
      <!-- 配合v-focus使用v-if -->
      <template v-if="show">
        <slot></slot>
        <el-form-item class="bottom-btn-group flex-center">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary"
                     :loading="loading"
                     @click="confirm">确定</el-button>
        </el-form-item>
      </template>
    </el-form>
  </el-popover>
</template>

<script>
  export default {
    name: "ConfirmPopover",
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      width: {
        type: [String, Number],
        default: '220px'
      },
      // 传入一个返回值为 Promise 的方法
      onConfirm: Function,
    },
    data() {
      return {
        show: false,
        loading: false,
      }
    },
    computed: {
      myWidth: function () {
        if (typeof this.width === 'number') {
          return this.width - 24 + 'px'
        } else {
          return this.width + ' - 24px';
        }
      }
    },
    methods: {
      cancel() {
        this.show = false;
      },
      confirm() {
        this.loading = true;
        this.onConfirm().then(res => {
          this.show = false;
        }).finally(() => {
          this.loading = false;
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .bottom-btn-group {
    margin-bottom: 8px;

    .el-button + .el-button {
      margin-left: 20px;
    }
  }
</style>
