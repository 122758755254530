<template>
  <div class="list-audio">
    <div class="list-like-audio">
      <!-- 播放按钮 -->
      <div class="list-like-audio-btn">
        <!-- <img :src="require(playing ? '':'')"/>-->
        <i @click="toggleStatus"
           :class="['cursor-pointer', playing ? 'el-icon-video-pause':'el-icon-video-play']"></i>
      </div>
      <!-- 播放按钮 -->
      <div class="list-like-audio-progress">
        <el-slider v-model="current"
                   :format-tooltip="formatCurrent"
                   :show-tooltip="false"
                   :min="0" :max="duration"
                   @change="handleChange"
        ></el-slider>
      </div>
      <!-- 时间 -->
      <div class="list-like-audio-timer">{{currentText}} / {{durationText}}</div>
    </div>
    <!-- 音频 -->
    <audio :src="src"
           :id="idString"
           @loadedmetadata="loadAudio"
           @ended="handleEnd"
           @pause="handlePause"
           @playing="handlePlaying"
           @timeupdate="updateProgress"
    ></audio>
  </div>
</template>

<script>
  export default {
    name: "ListAudio",
    props: {
      // 音频地址
      src: {
        type: String,
      },
      // 用来控制音频的唯一标识
      id: {
        type: [Number, String],
        required: true
      }
    },
    data() {
      return {
        playing: false,
        duration: 0,
        durationText: '00:00',
        current: 0,
      }
    },
    computed: {
      currentText: function () {
        return this.translateTime(this.current);
      },
      // 音频元素id，不带#
      idString: function () {
        return 'audio-' + this.id;
      },
      lastAudioIdString: function () {
        return this.$store.getters.lastAudioId;
      }
    },
    created() {
    },
    mounted() {
      this.$nextTick(() => {
        this._audioEl = document.querySelector('#' + this.idString);
      })
    },
    methods: {
      /* 更新进度 */
      updateProgress() {
        if (this._audioEl?.currentTime) {
          this.current = Math.ceil(this._audioEl.currentTime);
        }
      },
      /* 获取音频时长 */
      loadAudio() {
        if (this._audioEl?.duration) {
          this.duration = Math.ceil(this._audioEl.duration);
          this.durationText = this.translateTime(this.duration);
        }
      },
      handlePlaying() {
        console.log('playing')
        this.playing = true;
      },
      /* 先触发pause事件 */
      handleEnd() {
        console.log('ended')
        this._audioEl.currentTime = 0;
        this.current = 0;
      },
      handlePause() {
        console.log('paused')
        this.playing = false;
      },
      /* 拖动改变进度条 */
      handleChange() {
        let isPlayJust = false
        if (!this._audioEl.paused) {
          isPlayJust = true;
          this.toggleStatus()
        }
        this._audioEl.currentTime = this.current;
        if (isPlayJust) {
          this.toggleStatus()
        }
      },
      /* 切换播放、暂停状态 */
      toggleStatus() {
        if (this._audioEl.paused) {
          // 暂停上一个正在播放的音频
          let lastPlaying = this.lastAudioIdString;
          if (lastPlaying && lastPlaying !== this.idString) {
            let ctx = document.querySelector('#' + lastPlaying)
            if (ctx && !ctx.paused) {
              ctx.pause();
            }
          }
          // 播放当前音频
          this._audioEl.play();
          // 记录正在播放的音频id
          this.$store.commit('setLastAudioId', this.idString)
        } else {
          this._audioEl.pause();
        }
      },
      /* tooltip显示格式 */
      formatCurrent(val) {
        return this.translateTime(val)
      },
      /**
       * 秒 转 00:00 格式
       * @param{Number} t   second
       * @returns {string}
       */
      translateTime(t) {
        var duration = parseInt(t);
        var minute = parseInt(duration / 60 + '');
        var sec = duration % 60 + '';
        var isM0 = ':';
        if (minute === 0) {
          minute = '00';
        } else if (minute < 10) {
          minute = '0' + minute;
        }
        if (sec.length === 1) {
          sec = '0' + sec;
        }
        return minute + isM0 + sec;
      }
    },
  }
</script>

<style lang="scss" scoped>
  .list-audio {
    width: 100%;
    background: #FECA00;

    /* 仿 audio */
    .list-like-audio {
      width: 100%;
      height: 100%;
      line-height: 21px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;

      .list-like-audio-btn {
        width: 21px;
      }

      .list-like-audio-progress {
        width: 100%;
        margin: 0 8px 0 14px;
      }

      .list-like-audio-timer {
        font-size: 9px;
        color: #303133;
        white-space: nowrap;
      }
    }

    /* 覆盖el-slider默认样式 */
    ::v-deep .el-slider {

      .el-slider__bar, .el-slider__runway {
        height: 3px;
      }

      .el-slider__runway {
        background: #EBEDF0;
        border-radius: 2px;
        margin: 0;

        .el-slider__bar {
          background: #303133;
        }

        .el-slider__button-wrapper {
          width: 12px;
          height: 12px;
          top: -9px;

          .el-slider__button {
            width: 8px;
            height: 8px;
            border: none;
          }
        }
      }
    }
  }
</style>
